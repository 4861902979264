<template>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</template>

<script>

export default {
  name: "SupportVision",
  components: {
    IconPicker: () => import("@/Components/Commons/Inputs/Icon/IconPicker"),
    ColorPicker: () => import("@/Components/Commons/Inputs/Color/ColorPicker")
  },
  computed: {
    route() {
      return this.$route
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>